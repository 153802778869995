export const DotsIcon = ({
  strokeWidth = 1.5,
  ...otherProps
}: any) => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" {...otherProps} data-sentry-element="svg" data-sentry-component="DotsIcon" data-sentry-source-file="dots-icon.tsx">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" data-sentry-element="path" data-sentry-source-file="dots-icon.tsx" />
      <path d="M5 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" data-sentry-element="path" data-sentry-source-file="dots-icon.tsx" />
      <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" data-sentry-element="path" data-sentry-source-file="dots-icon.tsx" />
      <path d="M19 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" data-sentry-element="path" data-sentry-source-file="dots-icon.tsx" />
    </svg>;
};