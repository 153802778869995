"use client";

import React, { useState } from "react";
import { Plus } from "lucide-react";
import { Switch } from "../switch";
import { DotsIcon } from "@/components/icons/dots-icon";
export const SkeletonThree = () => {
  return <div className="group mx-auto mt-10 h-full w-full rounded-md  bg-white shadow-2xl dark:bg-neutral-800 dark:shadow-white/40 sm:w-[80%]" data-sentry-component="SkeletonThree" data-sentry-source-file="third.tsx">
      <div className="pointer-events-none absolute inset-x-0 bottom-0 z-[11] h-40 w-full bg-gradient-to-t from-white via-white to-transparent dark:from-black dark:via-black" />

      <div className="flex h-full w-full flex-1 flex-col space-y-2 ">
        <div className="flex justify-between border-b p-4 pb-2 dark:border-neutral-700">
          <p className="dark:text-muted-dark text-sm font-bold text-muted">
            Add LLM
          </p>
          <p className="shadow-derek dark:text-muted-dark flex flex-shrink-0 items-center space-x-1 rounded-md px-2 py-1 text-sm text-muted dark:bg-neutral-700">
            <Plus className="dark:text-muted-dark h-4 w-4 text-muted" data-sentry-element="Plus" data-sentry-source-file="third.tsx" />{" "}
            <span>Add</span>
          </p>
        </div>
        <div className="flex flex-col space-y-4 p-4">
          <Row title="Groq LLM" updatedAt="23rd March" data-sentry-element="Row" data-sentry-source-file="third.tsx" />
          <Row title="OpenAI GPT0" updatedAt="21st March" active data-sentry-element="Row" data-sentry-source-file="third.tsx" />
          <Row title="Stable DIffusion" updatedAt="3rd May" data-sentry-element="Row" data-sentry-source-file="third.tsx" />
          <Row title="Llama 2" updatedAt="1st April" active data-sentry-element="Row" data-sentry-source-file="third.tsx" />
          <Row title="Claude 200k" updatedAt="2nd June" active data-sentry-element="Row" data-sentry-source-file="third.tsx" />
        </div>
      </div>
    </div>;
};
export const Row = ({
  title,
  updatedAt,
  active = false
}: {
  title: string;
  updatedAt: string;
  active?: boolean;
}) => {
  const [checked, setChecked] = useState(active);
  return <div className="flex items-center justify-between" data-sentry-component="Row" data-sentry-source-file="third.tsx">
      <div className="flex items-center space-x-2">
        <p className="dark:text-muted-dark shadow-aceternity rounded-md px-1 py-0.5 text-xs text-muted dark:bg-neutral-700">
          {title}
        </p>
        <p className="dark:text-muted-dark text-xs text-muted">{updatedAt}</p>
      </div>
      <div className="flex items-center space-x-1">
        <Switch checked={checked} setChecked={setChecked} data-sentry-element="Switch" data-sentry-source-file="third.tsx" />
        <DotsIcon className="dark:text-muted-dark h-4 w-4 text-muted" data-sentry-element="DotsIcon" data-sentry-source-file="third.tsx" />
      </div>
    </div>;
};