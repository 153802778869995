"use client";

import clsx from "clsx";
import Image from "next/image";
import React, { useState } from "react";
interface IBlurImage {
  height?: any;
  width?: any;
  src?: string | any;
  objectFit?: any;
  className?: string | any;
  alt?: string | undefined;
  layout?: any;
  [x: string]: any;
}
export const BlurImage = ({
  height,
  width,
  src,
  className,
  objectFit,
  alt,
  layout,
  ...rest
}: IBlurImage) => {
  const [isLoading, setLoading] = useState(true);
  return <Image className={clsx("transition duration-300 transform", isLoading ? "blur-sm scale-105" : "blur-0 scale-100", className)} onLoadingComplete={() => setLoading(false)} src={src} width={width} height={height} loading="lazy" decoding="async" blurDataURL={src} layout={layout} alt={alt ? alt : "Avatar"} {...rest} data-sentry-element="Image" data-sentry-component="BlurImage" data-sentry-source-file="blur-image.tsx" />;
};