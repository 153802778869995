"use client";

import { stagger, useAnimate } from "framer-motion";
import React, { useState } from "react";
export const SkeletonTwo = () => {
  const [scope, animate] = useAnimate();
  const [animating, setAnimating] = useState(false);
  const handleAnimation = async () => {
    if (animating) return;
    setAnimating(true);
    await animate(".message", {
      opacity: [0, 1],
      y: [20, 0]
    }, {
      delay: stagger(0.5)
    });
    setAnimating(false);
  };
  return <div className="relative mt-4 h-full w-full" data-sentry-component="SkeletonTwo" data-sentry-source-file="second.tsx">
      <div className="pointer-events-none absolute inset-x-0 bottom-0 h-40 w-full bg-gradient-to-t from-white via-white to-transparent dark:from-black dark:via-black" />
      <div className="z-20 h-full rounded-[32px] border border-neutral-200 bg-neutral-100 p-4 dark:border-neutral-700 dark:bg-neutral-800">
        <div className="h-full rounded-[24px] border border-neutral-200 bg-white p-2 dark:border-neutral-700 dark:bg-black">
          <div className="mx-auto h-6 w-20 rounded-full bg-neutral-200/80 dark:bg-neutral-800/80" />
          <div onMouseEnter={handleAnimation} ref={scope} className="content mx-auto mt-4 w-[90%]">
            <UserMessage data-sentry-element="UserMessage" data-sentry-source-file="second.tsx">
              I need a killer name for my SaaS that helps creators turn their
              Twitter threads into cinematic short-form videos. Make it
              catchy!📱
            </UserMessage>
            <AIMessage data-sentry-element="AIMessage" data-sentry-source-file="second.tsx">Make it your own damn self... Catchy my a**..</AIMessage>
            <UserMessage data-sentry-element="UserMessage" data-sentry-source-file="second.tsx">What did you just say... ? 😳</UserMessage>
            <AIMessage data-sentry-element="AIMessage" data-sentry-source-file="second.tsx">
              *emergency fake reboot* Our sincerest apologies for gpt3.5&apos;s
              behavior. He&apos;s no longer working with us. Here are some names
              that might work for you:
              <br />
              threads.io
              <br />
              clipmatic.ai
              <br />
              viral.cut
              <br />
              tweel.io (tweet + reel)
              <br />
              snapthought.com
            </AIMessage>
            <UserMessage data-sentry-element="UserMessage" data-sentry-source-file="second.tsx">
              Okay, tweel.io is actually fire 🔥 You&apos;re not all bad I guess
            </UserMessage>
            <AIMessage data-sentry-element="AIMessage" data-sentry-source-file="second.tsx">
              Thanks! Now about those basic threads you&apos;ve been posting...
              Kidding! Kidding! 😅
            </AIMessage>
          </div>
        </div>
      </div>
    </div>;
};
const UserMessage = ({
  children
}: {
  children: React.ReactNode;
}) => {
  return <div className="message my-4 rounded-md bg-neutral-100 p-2 text-[10px] text-black dark:bg-neutral-800 dark:text-white sm:p-4 sm:text-xs" data-sentry-component="UserMessage" data-sentry-source-file="second.tsx">
      {children}
    </div>;
};
const AIMessage = ({
  children
}: {
  children: React.ReactNode;
}) => {
  return <div className="message my-4 rounded-md bg-black p-2 text-[10px] text-white dark:bg-white dark:text-black sm:p-4 sm:text-xs" data-sentry-component="AIMessage" data-sentry-source-file="second.tsx">
      {children}
    </div>;
};