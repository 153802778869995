"use client";

import React from "react";
import { motion } from "framer-motion";
import Image from "next/image";
import { BlurImage } from "../blur-image";
export const SkeletonOne = () => {
  return <div className="relative flex h-full gap-10 p-8" data-sentry-component="SkeletonOne" data-sentry-source-file="first.tsx">
      <div className=" group mx-auto h-full  w-full bg-white p-5 shadow-2xl dark:bg-neutral-900 md:w-[90%]">
        <div className="flex h-full w-full flex-1 flex-col space-y-2 opacity-20 dark:opacity-60 ">
          <UserMessage data-sentry-element="UserMessage" data-sentry-source-file="first.tsx">
            Need a logo for my coffee shop - something modern but cozy. Make it
            memorable! ☕
          </UserMessage>

          <AIMessage data-sentry-element="AIMessage" data-sentry-source-file="first.tsx">
            Design it yourself... I&apos;m not your free Canva alternative... 🙄
          </AIMessage>

          <UserMessage data-sentry-element="UserMessage" data-sentry-source-file="first.tsx">
            Did an AI just sass me about my design skills? 😳
          </UserMessage>

          <AIMessage data-sentry-element="AIMessage" data-sentry-source-file="first.tsx">
            *emergency creative reboot* Sorry! That was my artsy side acting up.
            Here are some logo concepts I can generate:
            <br />
            - Minimal coffee bean spiral
            <br />
            - Modern mug with steam waves
            <br />
            - Abstract cafe storefront
            <br />
            - Geometric coffee pour-over
            <br />- Lettermark with hidden coffee leaf
          </AIMessage>

          <UserMessage data-sentry-element="UserMessage" data-sentry-source-file="first.tsx">
            The spiral bean concept is actually fire 🔥 You&apos;re not so bad
            after all
          </UserMessage>

          <AIMessage data-sentry-element="AIMessage" data-sentry-source-file="first.tsx">
            Thanks! Now about that Comic Sans you were considering... Kidding!
            Kidding! 😅
          </AIMessage>
        </div>
      </div>
      <div className="absolute inset-0 flex flex-col items-center justify-center gap-4">
        <div className="z-20 mx-auto h-[250px] w-[250px] flex-shrink-0 rounded-[32px] border border-neutral-200 bg-neutral-100 p-2 transition duration-200 group-hover:scale-[1.02] dark:border-neutral-700 dark:bg-neutral-800 md:h-[300px] md:w-[300px]">
          <div className="flex-shrink-0 rounded-[24px] border border-neutral-200 bg-white p-2 dark:border-neutral-700 dark:bg-black">
            <BlurImage src="/skeleton-one.png" alt="header" width={800} height={800} className="aspect-square h-full w-full flex-shrink-0 rounded-[20px] object-cover object-bottom grayscale" data-sentry-element="BlurImage" data-sentry-source-file="first.tsx" />
          </div>
        </div>
        <div className="z-20 mx-auto h-[250px] w-[250px] flex-shrink-0 rounded-[32px] border border-neutral-200 bg-neutral-100 p-2 transition duration-200 group-hover:scale-[1.02] dark:border-neutral-700 dark:bg-neutral-800 md:h-[300px] md:w-[300px]">
          <div className="flex-shrink-0 rounded-[24px] border border-neutral-200 bg-white p-2 dark:border-neutral-700 dark:bg-black">
            <BlurImage src="/tyler.jpeg" alt="header" width={800} height={800} className="aspect-square h-full w-full flex-shrink-0 rounded-[20px] object-cover object-bottom grayscale" data-sentry-element="BlurImage" data-sentry-source-file="first.tsx" />
          </div>
        </div>
      </div>
      <div className="pointer-events-none absolute inset-x-0 bottom-0 z-40 h-60 w-full bg-gradient-to-t from-white via-white to-transparent dark:from-black dark:via-black" />
      <div className="pointer-events-none absolute inset-x-0 top-0 z-40 h-60 w-full bg-gradient-to-b from-white via-transparent to-transparent dark:from-black" />
    </div>;
};
const UserMessage = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const variants = {
    initial: {
      x: 0
    },
    animate: {
      x: 5,
      transition: {
        duration: 0.2
      }
    }
  };
  return <motion.div variants={variants} className="flex flex-row items-start  space-x-2  rounded-2xl bg-white p-2 dark:bg-neutral-900" data-sentry-element="unknown" data-sentry-component="UserMessage" data-sentry-source-file="first.tsx">
      <Image src="/avatar.jpg" alt="avatar" height="100" width="100" className="h-4 w-4 rounded-full md:h-10 md:w-10" data-sentry-element="Image" data-sentry-source-file="first.tsx" />
      <p className="text-[10px] text-neutral-500 sm:text-sm">{children}</p>
    </motion.div>;
};
const AIMessage = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const variantsSecond = {
    initial: {
      x: 0
    },
    animate: {
      x: 10,
      transition: {
        duration: 0.2
      }
    }
  };
  return <motion.div variants={variantsSecond} className="flex flex-row items-center   justify-start space-x-2 rounded-2xl bg-white  p-2 dark:bg-neutral-900 " data-sentry-element="unknown" data-sentry-component="AIMessage" data-sentry-source-file="first.tsx">
      <div className="h-4 w-4 flex-shrink-0 rounded-full bg-gradient-to-r from-pink-500 to-violet-500 md:h-10 md:w-10" />
      <p className="text-[10px] text-neutral-500 sm:text-sm">{children}</p>
    </motion.div>;
};