"use client";

import React, { useRef, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Image from "next/image";
import { cn } from "@/lib/utils";
import Balancer from "react-wrap-balancer";
import Link from "next/link";
import { Button } from "./button";
import { useCalEmbed } from "@/hooks/useCalEmbed";
import { CONSTANTS } from "@/constants/links";
import { useTheme } from "next-themes";
export function Hero() {
  const containerRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const calOptions = useCalEmbed({
    namespace: CONSTANTS.CALCOM_NAMESPACE,
    styles: {
      branding: {
        brandColor: CONSTANTS.CALCOM_BRAND_COLOR
      }
    },
    hideEventTypeDetails: CONSTANTS.CALCOM_HIDE_EVENT_TYPE_DETAILS,
    layout: CONSTANTS.CALCOM_LAYOUT
  });
  const {
    resolvedTheme
  } = useTheme();
  return <div ref={parentRef} className="relative flex min-h-screen flex-col items-center justify-center overflow-hidden bg-neutral-50 px-4 py-20 dark:bg-neutral-900 md:px-8 md:py-40" data-sentry-component="Hero" data-sentry-source-file="hero.tsx">
      <BackgroundGrids data-sentry-element="BackgroundGrids" data-sentry-source-file="hero.tsx" />
      <CollisionMechanism beamOptions={{
      initialX: -400,
      translateX: 600,
      duration: 7,
      repeatDelay: 3
    }} containerRef={containerRef} parentRef={parentRef} data-sentry-element="CollisionMechanism" data-sentry-source-file="hero.tsx" />
      <CollisionMechanism beamOptions={{
      initialX: -200,
      translateX: 800,
      duration: 4,
      repeatDelay: 3
    }} containerRef={containerRef} parentRef={parentRef} data-sentry-element="CollisionMechanism" data-sentry-source-file="hero.tsx" />
      <CollisionMechanism beamOptions={{
      initialX: 200,
      translateX: 1200,
      duration: 5,
      repeatDelay: 3
    }} containerRef={containerRef} parentRef={parentRef} data-sentry-element="CollisionMechanism" data-sentry-source-file="hero.tsx" />
      <CollisionMechanism containerRef={containerRef} parentRef={parentRef} beamOptions={{
      initialX: 400,
      translateX: 1400,
      duration: 6,
      repeatDelay: 3
    }} data-sentry-element="CollisionMechanism" data-sentry-source-file="hero.tsx" />

      <div className="relative z-20 mx-auto mb-4 mt-4 max-w-4xl text-balance text-center text-3xl font-semibold tracking-tight text-gray-700 dark:text-neutral-300 md:text-7xl">
        <Balancer data-sentry-element="Balancer" data-sentry-source-file="hero.tsx">
          {/* <motion.h2 className="text-xl md:text-4xl">
            {"Get your".split(" ").map((word, index) => (
              <motion.span
                initial={{
                  filter: "blur(10px)",
                  opacity: 0,
                  y: 10,
                }}
                animate={{
                  filter: "blur(0px)",
                  opacity: 1,
                  y: 0,
                }}
                transition={{
                  duration: 0.4,
                  delay: index * 0.05,
                }}
                className="inline-block"
                key={index}
              >
                {word}&nbsp;
              </motion.span>
            ))}
           </motion.h2> */}
          <motion.h2 data-sentry-element="unknown" data-sentry-source-file="hero.tsx">
            {"First 100 Customers In Days,".split(" ").map((word, index) => <motion.span initial={{
            filter: "blur(10px)",
            opacity: 0,
            y: 10
          }} animate={{
            filter: "blur(0px)",
            opacity: 1,
            y: 0
          }} transition={{
            duration: 0.4,
            delay: index * 0.05
          }} className="inline-block" key={index}>
                {word}&nbsp;
              </motion.span>)}
          </motion.h2>
          <motion.h2 data-sentry-element="unknown" data-sentry-source-file="hero.tsx">
            {"Not Months".split(" ").map((word, index) => <motion.span initial={{
            filter: "blur(10px)",
            opacity: 0,
            y: 10
          }} animate={{
            filter: "blur(0px)",
            opacity: 1,
            y: 0
          }} transition={{
            duration: 0.4,
            delay: index * 0.05
          }} className="inline-block" key={index}>
                {word}&nbsp;
              </motion.span>)}
          </motion.h2>
        </Balancer>
      </div>
      <motion.p initial={{
      opacity: 0,
      y: 10
    }} animate={{
      opacity: 1,
      y: 0
    }} transition={{
      duration: 0.2,
      delay: 0.5
    }} className="relative z-20 mx-auto mt-4 max-w-lg px-4 text-center text-base/6 text-gray-600 dark:text-gray-200" data-sentry-element="unknown" data-sentry-source-file="hero.tsx">
        The ultimate toolkit to take your idea from zero to 🚀—all in one place.
      </motion.p>
      <motion.div initial={{
      opacity: 0,
      y: 10
    }} animate={{
      opacity: 1,
      y: 0
    }} transition={{
      duration: 0.2,
      delay: 0.7
    }} className="mb-10 mt-8 flex w-full flex-col items-center justify-center gap-4 px-8 sm:flex-row md:mb-20" data-sentry-element="unknown" data-sentry-source-file="hero.tsx">
        <Button as={Link} href="/sign-up" variant="dark" className="hidden w-40 text-center md:block" data-sentry-element="Button" data-sentry-source-file="hero.tsx">
          Create account
        </Button>

        <Button data-cal-namespace={calOptions.namespace} data-cal-link={CONSTANTS.CALCOM_LINK} data-cal-config={`{"layout":"${calOptions.layout}"}`} as="button" variant="primary" className="hidden w-40 md:block" data-sentry-element="Button" data-sentry-source-file="hero.tsx">
          Book a call
        </Button>
      </motion.div>
      <motion.div initial={{
      opacity: 0,
      y: 20
    }} animate={{
      opacity: 1,
      y: 0
    }} transition={{
      duration: 0.4,
      delay: 0.9,
      ease: "easeOut"
    }} ref={containerRef} className="relative mx-auto max-w-7xl rounded-[32px] border border-neutral-200/50 bg-neutral-100 p-2 backdrop-blur-lg dark:border-neutral-700 dark:bg-neutral-800/50 md:p-4" data-sentry-element="unknown" data-sentry-source-file="hero.tsx">
        <div className="rounded-[24px] border border-neutral-200 bg-white p-2 dark:border-neutral-700 dark:bg-black">
          <Image src={resolvedTheme === "dark" ? "/viral-launch-og-image-dark.png" : "/viral-launch-og-image-white.png"} alt="header" width={1920} height={1080} className="rounded-[20px]" data-sentry-element="Image" data-sentry-source-file="hero.tsx" />
        </div>
      </motion.div>
    </div>;
}
const BackgroundGrids = () => {
  return <div className="pointer-events-none absolute inset-0 z-0 grid h-full w-full -rotate-45 transform select-none grid-cols-2 gap-10 md:grid-cols-4" data-sentry-component="BackgroundGrids" data-sentry-source-file="hero.tsx">
      <div className="relative h-full w-full">
        <GridLineVertical className="left-0" data-sentry-element="GridLineVertical" data-sentry-source-file="hero.tsx" />
        <GridLineVertical className="left-auto right-0" data-sentry-element="GridLineVertical" data-sentry-source-file="hero.tsx" />
      </div>
      <div className="relative h-full w-full">
        <GridLineVertical className="left-0" data-sentry-element="GridLineVertical" data-sentry-source-file="hero.tsx" />
        <GridLineVertical className="left-auto right-0" data-sentry-element="GridLineVertical" data-sentry-source-file="hero.tsx" />
      </div>
      <div className="relative h-full w-full bg-gradient-to-b from-transparent via-neutral-100 to-transparent dark:via-neutral-800">
        <GridLineVertical className="left-0" data-sentry-element="GridLineVertical" data-sentry-source-file="hero.tsx" />
        <GridLineVertical className="left-auto right-0" data-sentry-element="GridLineVertical" data-sentry-source-file="hero.tsx" />
      </div>
      <div className="relative h-full w-full">
        <GridLineVertical className="left-0" data-sentry-element="GridLineVertical" data-sentry-source-file="hero.tsx" />
        <GridLineVertical className="left-auto right-0" data-sentry-element="GridLineVertical" data-sentry-source-file="hero.tsx" />
      </div>
    </div>;
};
const CollisionMechanism = React.forwardRef<HTMLDivElement, {
  containerRef: React.RefObject<HTMLDivElement>;
  parentRef: React.RefObject<HTMLDivElement>;
  beamOptions?: {
    initialX?: number;
    translateX?: number;
    initialY?: number;
    translateY?: number;
    rotate?: number;
    className?: string;
    duration?: number;
    delay?: number;
    repeatDelay?: number;
  };
}>(({
  parentRef,
  containerRef,
  beamOptions = {}
}, ref) => {
  const beamRef = useRef<HTMLDivElement>(null);
  const [collision, setCollision] = useState<{
    detected: boolean;
    coordinates: {
      x: number;
      y: number;
    } | null;
  }>({
    detected: false,
    coordinates: null
  });
  const [beamKey, setBeamKey] = useState(0);
  const [cycleCollisionDetected, setCycleCollisionDetected] = useState(false);
  useEffect(() => {
    const checkCollision = () => {
      if (beamRef.current && containerRef.current && parentRef.current && !cycleCollisionDetected) {
        const beamRect = beamRef.current.getBoundingClientRect();
        const containerRect = containerRef.current.getBoundingClientRect();
        const parentRect = parentRef.current.getBoundingClientRect();
        if (beamRect.bottom >= containerRect.top) {
          const relativeX = beamRect.left - parentRect.left + beamRect.width / 2;
          const relativeY = beamRect.bottom - parentRect.top;
          setCollision({
            detected: true,
            coordinates: {
              x: relativeX,
              y: relativeY
            }
          });
          setCycleCollisionDetected(true);
          if (beamRef.current) {
            beamRef.current.style.opacity = "0";
          }
        }
      }
    };
    const animationInterval = setInterval(checkCollision, 50);
    return () => clearInterval(animationInterval);
  }, [cycleCollisionDetected, containerRef]);
  useEffect(() => {
    if (collision.detected && collision.coordinates) {
      setTimeout(() => {
        setCollision({
          detected: false,
          coordinates: null
        });
        setCycleCollisionDetected(false);
        // Set beam opacity to 0
        if (beamRef.current) {
          beamRef.current.style.opacity = "1";
        }
      }, 2000);

      // Reset the beam animation after a delay
      setTimeout(() => {
        setBeamKey(prevKey => prevKey + 1);
      }, 2000);
    }
  }, [collision]);
  return <>
      <motion.div key={beamKey} ref={beamRef} animate="animate" initial={{
      translateY: beamOptions.initialY || "-200px",
      translateX: beamOptions.initialX || "0px",
      rotate: beamOptions.rotate || -45
    }} variants={{
      animate: {
        translateY: beamOptions.translateY || "800px",
        translateX: beamOptions.translateX || "700px",
        rotate: beamOptions.rotate || -45
      }
    }} transition={{
      duration: beamOptions.duration || 8,
      repeat: Infinity,
      repeatType: "loop",
      ease: "linear",
      delay: beamOptions.delay || 0,
      repeatDelay: beamOptions.repeatDelay || 0
    }} className={cn("absolute left-96 top-20 m-auto h-14 w-px rounded-full bg-gradient-to-t from-orange-500 via-yellow-500 to-transparent", beamOptions.className)} />
      <AnimatePresence>
        {collision.detected && collision.coordinates && <Explosion key={`${collision.coordinates.x}-${collision.coordinates.y}`} className="" style={{
        left: `${collision.coordinates.x + 20}px`,
        top: `${collision.coordinates.y}px`,
        transform: "translate(-50%, -50%)"
      }} />}
      </AnimatePresence>
    </>;
});
CollisionMechanism.displayName = "CollisionMechanism";
const Explosion = ({
  ...props
}: React.HTMLProps<HTMLDivElement>) => {
  const spans = Array.from({
    length: 20
  }, (_, index) => ({
    id: index,
    initialX: 0,
    initialY: 0,
    directionX: Math.floor(Math.random() * 80 - 40),
    directionY: Math.floor(Math.random() * -50 - 10)
  }));
  return <div {...props} className={cn("absolute z-50 h-2 w-2", props.className)} data-sentry-component="Explosion" data-sentry-source-file="hero.tsx">
      <motion.div initial={{
      opacity: 0
    }} animate={{
      opacity: [0, 1, 0]
    }} exit={{
      opacity: 0
    }} transition={{
      duration: 1,
      ease: "easeOut"
    }} className="absolute -inset-x-10 top-0 m-auto h-[4px] w-10 rounded-full bg-gradient-to-r from-transparent via-orange-500 to-transparent blur-sm" data-sentry-element="unknown" data-sentry-source-file="hero.tsx"></motion.div>
      {spans.map(span => <motion.span key={span.id} initial={{
      x: span.initialX,
      y: span.initialY,
      opacity: 1
    }} animate={{
      x: span.directionX,
      y: span.directionY,
      opacity: 0
    }} transition={{
      duration: Math.random() * 1.5 + 0.5,
      ease: "easeOut"
    }} className="absolute h-1 w-1 rounded-full bg-gradient-to-b from-orange-500 to-yellow-500" />)}
    </div>;
};
const GridLineVertical = ({
  className,
  offset
}: {
  className?: string;
  offset?: string;
}) => {
  return <div style={{
    "--background": "#ffffff",
    "--color": "rgba(0, 0, 0, 0.2)",
    "--height": "5px",
    "--width": "1px",
    "--fade-stop": "90%",
    "--offset": offset || "150px",
    //-100px if you want to keep the line inside
    "--color-dark": "rgba(255, 255, 255, 0.3)",
    maskComposite: "exclude"
  } as React.CSSProperties} className={cn("absolute top-[calc(var(--offset)/2*-1)] h-[calc(100%+var(--offset))] w-[var(--width)]", "bg-[linear-gradient(to_bottom,var(--color),var(--color)_50%,transparent_0,transparent)]", "[background-size:var(--width)_var(--height)]", "[mask:linear-gradient(to_top,var(--background)_var(--fade-stop),transparent),_linear-gradient(to_bottom,var(--background)_var(--fade-stop),transparent),_linear-gradient(black,black)]", "[mask-composite:exclude]", "z-30", "dark:bg-[linear-gradient(to_bottom,var(--color-dark),var(--color-dark)_50%,transparent_0,transparent)]", className)} data-sentry-component="GridLineVertical" data-sentry-source-file="hero.tsx"></div>;
};