import { cn } from "@/lib/utils";
import { AnimationProps, MotionProps } from "framer-motion";
import React from "react";
import Balancer from "react-wrap-balancer";
export const Subheading = ({
  className,
  as: Tag = "h2",
  children,
  ...props
}: {
  className?: string;
  as?: any;
  children: any;
  props?: React.HTMLAttributes<HTMLHeadingElement | AnimationProps>;
} & MotionProps & React.HTMLAttributes<HTMLHeadingElement | AnimationProps>) => {
  return <Tag className={cn("text-sm md:text-base  max-w-4xl text-left my-4 mx-auto", "text-muted text-center font-normal dark:text-muted-dark", className)} data-sentry-element="Tag" data-sentry-component="Subheading" data-sentry-source-file="subheading.tsx">
      <Balancer data-sentry-element="Balancer" data-sentry-source-file="subheading.tsx">{children}</Balancer>
    </Tag>;
};