"use client";

import { useEffect, useState } from "react";
import { Heading } from "./heading";
import { Subheading } from "./subheading";
import Image from "next/image";
import { AnimatePresence, motion } from "framer-motion";
export const Companies = () => {
  const headingVariations = [{
    heading: "Used by tech giants*",
    subheading: "*Okay, not really. We just launched. But I did convince my mom to use it, and she's harder to impress than a YC partner. 🤷‍♂️"
  }, {
    heading: "Trusted by industry leaders*",
    subheading: "*Well, my cat watched me build it and didn't walk away. That's better feedback than most VC pitch meetings. 😼"
  }, {
    heading: "Backed by top investors*",
    subheading: "*If by investors you mean my savings account and a very understanding spouse. Still better than 99% of pitch decks. 💸"
  }, {
    heading: "Built with decades of experience*",
    subheading: "*I'm 35, so technically it's multiple decades. Most of those were spent watching Naruto, but hey, who's counting? 🐢"
  }, {
    heading: "As seen on TechCrunch*",
    subheading: "*Not yet, but I did get 3 likes on my launch tweet. Pretty much the same thing, right? 🚀"
  }, {
    heading: "Used by Fortune 500 companies*",
    subheading: "*They're on my vision board. Manifestation counts, right? 🎯"
  }];

  // Use useState to keep the selected variation consistent during render
  const [selectedVariation] = useState(() => headingVariations[Math.floor(Math.random() * headingVariations.length)]);
  let [logos, setLogos] = useState([[{
    title: "Netflix (we wish)",
    src: "/netflix.png"
  }, {
    title: "Google (in our dreams)",
    src: "/google.webp"
  }, {
    title: "Meta (maybe next year)",
    src: "/meta.png"
  }, {
    title: "OnlyFans (don't tell mom)",
    src: "/onlyfans.png"
  }], [{
    title: "Amazon (paid the delivery guy to try it out)",
    src: "/netflix.png"
  }, {
    title: "Microsoft (404: customer not found)",
    src: "/google.webp"
  }, {
    title: "Tesla (while I'm in the Uber)",
    src: "/meta.png"
  }, {
    title: "Spotify (im all outa jokes, come back next week)",
    src: "/onlyfans.png"
  }]]);
  const [activeLogoSet, setActiveLogoSet] = useState(logos[0]);
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const flipLogos = () => {
    setLogos(currentLogos => {
      const newLogos = [...currentLogos.slice(1), currentLogos[0]];
      setActiveLogoSet(newLogos[0]);
      setIsAnimating(true);
      return newLogos;
    });
  };
  useEffect(() => {
    if (!isAnimating) {
      const timer = setTimeout(() => {
        flipLogos();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isAnimating]);
  return <div className="relative z-20 py-10 md:py-40" data-sentry-component="Companies" data-sentry-source-file="companies.tsx">
      <Heading as="h2" data-sentry-element="Heading" data-sentry-source-file="companies.tsx">{selectedVariation.heading}</Heading>
      <Subheading className="text-center" data-sentry-element="Subheading" data-sentry-source-file="companies.tsx">
        {selectedVariation.subheading}
      </Subheading>

      <div className="relative mt-20 flex h-full w-full flex-wrap justify-center gap-10 md:gap-40">
        <AnimatePresence mode="popLayout" onExitComplete={() => setIsAnimating(false)} data-sentry-element="AnimatePresence" data-sentry-source-file="companies.tsx">
          {activeLogoSet.map((logo, idx) => <motion.div initial={{
          y: 40,
          opacity: 0,
          filter: "blur(10px)"
        }} animate={{
          y: 0,
          opacity: 1,
          filter: "blur(0px)"
        }} exit={{
          y: -40,
          opacity: 0,
          filter: "blur(10px)"
        }} transition={{
          duration: 0.8,
          delay: 0.1 * idx,
          ease: [0.4, 0, 0.2, 1]
        }} key={logo.title} className="group relative">
              <Image src={logo.src} alt={logo.title} width="100" height="100" className="h-10 w-20 object-contain grayscale transition-all duration-300 hover:grayscale-0 md:h-20 md:w-40" />
              <span className="absolute -bottom-6 left-1/2 -translate-x-1/2 whitespace-nowrap text-xs text-muted-foreground opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                {logo.title}
              </span>
            </motion.div>)}
        </AnimatePresence>
      </div>
    </div>;
};