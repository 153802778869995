"use client";

import { cn } from "@/lib/utils";
import { motion } from "framer-motion";
import { useTheme } from "next-themes";
import React, { useId } from "react";
export const Background = () => {
  return <div className="pointer-events-none absolute inset-0 z-0 h-full w-full" data-sentry-component="Background" data-sentry-source-file="background.tsx">
      <div className="pointer-events-none absolute inset-0 h-full w-full bg-white [mask-image:radial-gradient(ellipse_at_center,transparent,white)] dark:bg-black" />
      {Array.from({
      length: 6
    }).map((_, index) => <div className="flex" key={"grid-column" + index}>
          {Array.from({
        length: 10
      }).map((_, index) => <GridBlock key={`grid-row` + index} />)}
        </div>)}
    </div>;
};
const GridBlock = () => {
  return <div className="flex w-60 flex-col items-start  justify-center" data-sentry-component="GridBlock" data-sentry-source-file="background.tsx">
      <div className="flex items-center justify-center">
        <Dot data-sentry-element="Dot" data-sentry-source-file="background.tsx" />
        <SVG data-sentry-element="SVG" data-sentry-source-file="background.tsx" />
        {/* <Dot /> */}
      </div>
      <SVGVertical className="ml-3" data-sentry-element="SVGVertical" data-sentry-source-file="background.tsx" />
    </div>;
};
const Dot = () => {
  return <div className="flex h-6 w-6 items-center justify-center rounded-full bg-white dark:bg-neutral-900" data-sentry-component="Dot" data-sentry-source-file="background.tsx">
      <div className="h-2 w-2 rounded-full bg-neutral-200 dark:bg-neutral-700" />
    </div>;
};
const SVGVertical = ({
  className
}: {
  className?: string;
}) => {
  const width = 1;
  const height = 140;
  const id = useId();
  return <motion.svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" className={cn("text-neutral-100 dark:text-neutral-800", className)} data-sentry-element="unknown" data-sentry-component="SVGVertical" data-sentry-source-file="background.tsx">
      <path d="M0.5 0.5V479" stroke="currentColor" strokeWidth={2} data-sentry-element="path" data-sentry-source-file="background.tsx" />
      <motion.path d="M0.5 0.5V479" stroke={`url(#gradient-${id})`} strokeWidth={2} data-sentry-element="unknown" data-sentry-source-file="background.tsx" />

      <defs data-sentry-element="defs" data-sentry-source-file="background.tsx">
        <motion.linearGradient id={`gradient-${id}`} initial={{
        x1: 2,
        y1: -200,
        x2: 2,
        y2: -100
      }} animate={{
        x1: 2,
        y1: 400,
        x2: 2,
        y2: 600
      }} transition={{
        repeat: Infinity,
        duration: Math.random() * 2 + 1,
        delay: Math.floor(Math.random() * 6) + 5
      }} gradientUnits="userSpaceOnUse" data-sentry-element="unknown" data-sentry-source-file="background.tsx">
          <motion.stop offset="0%" stopColor="transparent" data-sentry-element="unknown" data-sentry-source-file="background.tsx" />
          <motion.stop offset="50%" stopColor="var(--neutral-200)" data-sentry-element="unknown" data-sentry-source-file="background.tsx" />
          <motion.stop offset="100%" stopColor="transparent" data-sentry-element="unknown" data-sentry-source-file="background.tsx" />
        </motion.linearGradient>
      </defs>
    </motion.svg>;
};
const SVG = ({
  className
}: {
  className?: string;
}) => {
  const width = 300;
  const height = 1;
  const id = useId();
  return <motion.svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" className={cn("text-neutral-100 dark:text-neutral-800", className)} data-sentry-element="unknown" data-sentry-component="SVG" data-sentry-source-file="background.tsx">
      <path d="M0.5 0.5H479" stroke="currentColor" data-sentry-element="path" data-sentry-source-file="background.tsx" />
      <motion.path d="M0.5 0.5H479" stroke={`url(#gradient-${id})`} strokeWidth={1} data-sentry-element="unknown" data-sentry-source-file="background.tsx" />

      <defs data-sentry-element="defs" data-sentry-source-file="background.tsx">
        <motion.linearGradient id={`gradient-${id}`} initial={{
        x1: -200,
        y1: 0,
        x2: -100,
        y2: 0
      }} animate={{
        x1: 400,
        y1: 0,
        x2: 600,
        y2: 0
      }} transition={{
        repeat: Infinity,
        duration: Math.random() * 2 + 1,
        delay: Math.floor(Math.random() * 6) + 5
      }} gradientUnits="userSpaceOnUse" data-sentry-element="unknown" data-sentry-source-file="background.tsx">
          <motion.stop offset="0%" stopColor="transparent" data-sentry-element="unknown" data-sentry-source-file="background.tsx" />
          <motion.stop offset="50%" stopColor="var(--neutral-200)" data-sentry-element="unknown" data-sentry-source-file="background.tsx" />
          <motion.stop offset="100%" stopColor="transparent" data-sentry-element="unknown" data-sentry-source-file="background.tsx" />
        </motion.linearGradient>
      </defs>
    </motion.svg>;
};

// Use the below rect to debug linear gradient
{
  /* <motion.rect width={width} height={width} fill={`url(#gradient-${id})`} /> */
}